@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 2s ease-out, transform 2s ease-out;
    will-change: opacity, transform;
  }

  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}